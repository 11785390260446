export function scrollToElement(elementId) {
    if(window.location.pathname !== '/' && !window.location.pathname.includes('#')){
        window.location.href = `/`;
    }
    var targetElement = document.getElementById(elementId);
    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
    } else {
        console.error("Element with ID '" + elementId + "' not found.");
    }
}