// Import the needed functions from the SDKs.
import 'firebase/firestore';
import 'firebase/auth';

// Import the needed functions from the SDKs.
import { initializeApp } from 'firebase/app';
import { getFirestore, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Firebase configuration
import { firebaseConfig } from './secret';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, storage, collection, ref, uploadBytes, getDownloadURL };