import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { startRegisterWithEmailPasswordName } from "../../actions/auth";

const StyledDiv = styled.div`
    width: 100%;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    #form-div {
        width: 95%;
        max-width: 768px;
        flex: 1;
        border-radius: 18px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 7.018px 14.036px 0px rgba(171, 190, 209, 0.40);
        padding: 21px 27px;
        margin-left: 20px;
        h3 {
            color: #24393E;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            color: #24393E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 143.842%; /* 23.015px */
        }
        input, textarea {
            width: 100%;
            border-radius: 28px;
            border: 1.5px solid #CCC;
            padding: 15px 10px 15px 25px;
            color: #999;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 143.842%; /* 23.015px */
            margin: 10px 0;
        }
        input {
            height: 56px;
        }
        button {
            border-radius: 25.283px;
            background: #9BCB49;
            color: #24393E;
            text-align: center;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 20.227px; /* 101.133% */
            border: none;
            padding: 16px;
            width: 221px;
            cursor: pointer;
        }
    }
`;

export const Signup = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        name: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        dispatch(startRegisterWithEmailPasswordName(formData.email, formData.password));
    };

    useEffect(() => {
        window.scrollTo(0,0);
    },[]);

    return (
        <StyledDiv>
            <div id="form-div">
                <h3>Registrar usuario</h3>
                <p>Ingresa tus credenciales para registrarte.</p>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12} md={6} lg={6} >
                            <input
                                type="text"
                                placeholder="Correo"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={12} md={6} lg={6} >
                            <input 
                                type="password"
                                placeholder="Contraseña"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <button type="submit">Registrar</button>
                </form>
            </div>
        </StyledDiv>
    );
}