import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { db } from "../../firebase/firebase-config";
import { collection, getDocs, serverTimestamp, updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import FullScreenSpinner from "../spinner/Spiner";

const StyledDiv = styled.div`
    padding: 10px 20px;
    #form {
        flex: 1;
        border-radius: 18px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 7.018px 14.036px 0px rgba(171, 190, 209, 0.40);
        padding: 21px 27px;
        margin-left: 20px;
        h3 {
            color: #24393E;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            color: #24393E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 143.842%; /* 23.015px */
        }
        input {
            width: 100%;
            border-radius: 28px;
            border: 1.5px solid #CCC;
            padding: 15px 10px 15px 25px;
            color: #999;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 143.842%; /* 23.015px */
            margin: 10px 0;
            height: 56px;
        }
        button {
            border-radius: 25.283px;
            background: #9BCB49;
            color: #24393E;
            text-align: center;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 20.227px; /* 101.133% */
            border: none;
            padding: 16px;
            width: 221px;
        }
        #erase-btn {
            background: #B80F0A;
            color: #FFF;
            margin-left: 20px;
        }
        @media only screen and (width < 768px) {
            button {
                margin: 5px 20px;
            }
        }
    }
`;

export const ManageVideo = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [lastVideoUrl, setLastVideoUrl] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchVideoData = async () => {
        setLoading(true);
        try {
            const querySnapshot = await getDocs(collection(db, 'video'));

            if (!querySnapshot.empty) {
                // Get the first document
                const firstDocument = querySnapshot.docs[0].data();
                setVideoUrl(firstDocument?.videoUrl);
                setLastVideoUrl(firstDocument?.videoUrl);
            } else {
                console.log('No documents found in the "video" collection.');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching video data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVideoData();
    }, []);

    const handleUpdateVideoUrl = async () => {
        setLoading(true);
        try {
            const videoCollection = collection(db, 'video');
        
            // Check if there is a document to update
            const querySnapshot = await getDocs(videoCollection);
            if (!querySnapshot.empty) {
                const firstDocument = querySnapshot.docs[0];
        
                if (videoUrl !== lastVideoUrl) {
                    await updateDoc(firstDocument.ref, {
                        videoUrl: videoUrl,
                        updatedAt: serverTimestamp(),
                    });

                    Swal.fire({
                        icon: 'success',
                        title: '¡Muy bien!',
                        text: 'Video actualizado correctamente',
                        showCancelButton: false,
                        confirmButtonColor: '#9BCB49',
                        confirmButtonText: 'Ok',
                    });
            
                    fetchVideoData();
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Ingresa una nueva URL',
                        text: 'La nueva URL es igual a la anterior',
                        showCancelButton: false,
                        confirmButtonColor: '#FFD300',
                        confirmButtonText: 'Ok',
                    });
                }
            } else {
                console.log('No documents found in the "video" collection.');
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrió un problema',
                    text: 'Hubo un error al obtener la URL actual.',
                    showCancelButton: false,
                    confirmButtonColor: '#B80F0A',
                    confirmButtonText: 'Ok',
                });
            }
            setLoading(false);
        } catch (error) {
            console.error('Error updating video URL:', error);
            Swal.fire({
                title: 'Algo salió mal...',
                text: 'Ocurrió un problema al actualizar el video.',
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            });
            setLoading(false);
        }
    };

    const handleEraseVideoUrl = async () => {
        setLoading(true);
        try {
            const videoCollection = collection(db, 'video');
        
            // Check if there is a document to update
            const querySnapshot = await getDocs(videoCollection);
            if (!querySnapshot.empty) {
                const firstDocument = querySnapshot.docs[0];
                await updateDoc(firstDocument.ref, {
                    videoUrl: "",
                    updatedAt: serverTimestamp(),
                });

                Swal.fire({
                    icon: 'success',
                    title: '¡Muy bien!',
                    text: 'Video actualizado correctamente',
                    showCancelButton: false,
                    confirmButtonColor: '#9BCB49',
                    confirmButtonText: 'Ok',
                });
        
                fetchVideoData();
            } else {
                console.log('No documents found in the "video" collection.');
                Swal.fire({
                    icon: 'error',
                    title: 'Ocurrió un problema',
                    text: 'Hubo un error al obtener la URL actual.',
                    showCancelButton: false,
                    confirmButtonColor: '#B80F0A',
                    confirmButtonText: 'Ok',
                });
            }
            setLoading(false);
        } catch (error) {
            console.error('Error updating video URL:', error);
            Swal.fire({
                title: 'Algo salió mal...',
                text: 'Ocurrió un problema al actualizar el video.',
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            });
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setVideoUrl(value);
    };

    return (
        <StyledDiv>
            <div id='form'>
                <h3>Editar video</h3>
                <p>Ingresa la URL del video principal</p>
                <input
                    type="text"
                    placeholder="URL"
                    name="subject"
                    value={videoUrl}
                    onChange={handleChange}
                />
                <button onClick={handleUpdateVideoUrl}>Actualizar</button>
                <button id="erase-btn" onClick={handleEraseVideoUrl}>Quitar video</button>
            </div>
            {loading ? 
                <FullScreenSpinner/>
            : null}
        </StyledDiv>
    )
}