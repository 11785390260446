import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AboutUs } from "./aboutUs";
import { Services } from "./services";
import { Clients } from "./clients";
import { PhotosSlider } from "./photosSlider";
import { Video } from "./video";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import { scrollToElement } from "../../utils/utils";

const StyledBanner = styled.div`
    width: 100%;
    height: 770px;
    background: none;
    position: relative;
    margin-bottom: 260px;
    #banner-div {
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: #00000099;
        flex-direction: column;
    }
    h1 {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 87px;
        font-style: normal;
        font-weight: 700;
        line-height: 70%;
        margin-top: 0;
        margin-bottom: 39px;
        span {
            font-size: 47px;
        }
    }
    p {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 0;
        margin-bottom: 64px;
    }
    #green-btn {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 27.309px; /* 97.531% */
        border-radius: 99px;
        background: #9BCB49;
        width: 348px;
        padding: 19.116px 43.694px;
        border: none;
    }
    #green-div {
        width: 90%;
        max-width: 1400px;
        height: 138px;
        background: #9BCB49;
        position: absolute;
        bottom: -69px;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 39px 0 50px;
        p {
            font-size: 24px;
            margin-bottom: 0;
            margin-right: 20px;
        }
        #transparent-btn {
            border-radius: 99px;
            border: 3px solid #FFF;
            display: flex;
            width: 250px;
            height: 54.652px;
            padding: 15.839px 36.204px;
            justify-content: center;
            align-items: center;
            gap: 11.314px;
            flex-shrink: 0;
            background: transparent;
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 21.154px;
            font-style: normal;
            font-weight: 700;
            line-height: 22.627px; /* 106.965% */
        }
    }
    @media only screen and (width <= 1200px) and (width > 768px) {
        height: 600px;
        margin-bottom: 150px;
        h1 {
            font-size: 72px;
            span {
                font-size: 40px;
            }
        }
        p {
            font-size: 28px;
        }
        #green-btn {
            font-size: 24px;
            padding: 14px 36px;
            width: 300px;
        }
        #green-div {
            p {
                font-size: 22px;
            }
            #transparent-btn {
                font-size: 22px;
                width: 200px;
                padding: 12px;
            }
        }
    }
    @media only screen and (width <= 768px) {
        height: 500px;
        margin-bottom: 130px;
        h1 {
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: -50px;
            span {
                font-size: 22px;
            }
        }
        p {
            font-size: 20px;
            margin-bottom: 30px;
        }
        #green-btn {
            font-size: 20px;
            padding: 10px 26px;
            width: 230px;
        }
        #green-div {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            flex-direction: column;
            justify-content: space-around;
            p {
                font-size: 18px;
                margin-right: 0;
            }
            #transparent-btn {
                font-size: 16px;
                width: 240px;
                height: 36px;
            }
        }
    }
    #banner-div, #green-div {
        z-index: 2;
    }
`;

export const Home = () => {
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        const fetchVideoData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'video'));
    
                if (!querySnapshot.empty) {
                    // Get the first document
                    const firstDocument = querySnapshot.docs[0].data();
                    setVideoUrl(firstDocument?.videoUrl);
                } else {
                    console.log('No documents found in the "video" collection.');
                }
            } catch (error) {
                console.error('Error fetching video data:', error);
            }
        };

        fetchVideoData();
    }, []);

    const handleClick = () => {
        scrollToElement('contact-us');
    }

    return (
        <>
        <StyledBanner id='home' video={videoUrl}>
            <div id="banner-div">
                <h1>Soluciones en<br/><span>equipos de cómputo</span></h1>
                <p>Vanguardia al alcance de las más grandes empresas tecnológicas</p>
                <button id="green-btn" onClick={handleClick}>Ver solución</button>
                <Video/>
            </div>
            <div id="green-div">
                <p>Cotiza las soluciones necesarias para mantenerte actualizado.</p>
                <button id="transparent-btn" onClick={handleClick}>Cotizar</button>
            </div>
        </StyledBanner>
        <AboutUs/>
        <PhotosSlider/>
        <Services/>
        <Clients/>
        </>
    )
}