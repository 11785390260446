import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { db } from '../../firebase/firebase-config';
import { collection, getDocs } from 'firebase/firestore';
import bannerBg from '../../assets/home/banner-bg.png';

const StyledDiv = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url(${bannerBg}) center;
	background-size: cover;
	z-index: -1;
	@media (min-aspect-ratio: 16/9) {
		.responsive-media {
			width: 100%;
			height: auto;
		}
	}

	@media (max-aspect-ratio: 16/9) {
		.responsive-media {
			width: auto;
			height: 100%;
		}
	}
`;

export const Video = () => {
	const [videoUrl, setVideoUrl] = useState('');

	useEffect(() => {
		const fetchVideoData = async () => {
			try {
				const querySnapshot = await getDocs(collection(db, 'video'));

				if (!querySnapshot.empty) {
					// Get the first document
					const firstDocument = querySnapshot.docs[0].data();
					setVideoUrl(firstDocument?.videoUrl);
				} else {
					console.log(
						'No documents found in the "video" collection.'
					);
				}
			} catch (error) {
				console.error('Error fetching video data:', error);
			}
		};

		fetchVideoData();
	}, []);

	return (
		<StyledDiv>
			{videoUrl?.length >= 10 ? (
				<ReactPlayer
					className='react-player responsive-media'
					url={videoUrl}
					width='100%'
					height='100%'
					playing={true}
					loop={true}
					muted={true}
					config={{
						youtube: {
							playerVars: { autoplay: 1 },
						},
					}}
				/>
			) : null}
		</StyledDiv>
	);
};
