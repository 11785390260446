import {
  getAuth,
  signOut,
  updateProfile,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { types } from '../types/types';
import { cleanSession, createUser } from './user';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const auth = getAuth();

export const startRegisterWithEmailPasswordName = (
  email,
  password,
  name,
) => {
  return (dispatch, getState) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async ({ user }) => {
        await updateProfile(user, { displayName: name });
        dispatch(login(user.uid, user.displayName));
        dispatch(isLogged());
        Swal.fire({
          icon: 'success',
          title: '¡Muy bien!',
          text: 'Usuario creado exitosamente',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        });
      })
      .catch((e) => {
        if(e.code.includes('auth/email-already-in-use')) {
          Swal.fire({
            title: 'Algo salió mal...',
            text: 'El correo que ingresaste ya está en uso.',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
          });
        } else {
          Swal.fire({
            title: 'Algo salió mal...',
            text: 'Ocurrió un problema. Intenta de nuevo o prueba otro método de inicio de sesión.',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
          });
        }
      });
  };
};

export const startLoginEmailPassword = (email, password) => {
  return (dispatch) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(({ user }) => {
        dispatch(login(user.uid, user.displayName));
        dispatch(isLogged());
        window.location.href = '/admin';
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const login = (uid, displayName) => ({
  type: types.login,
  payload: {
    uid,
    displayName,
  },
});

export const startLogout = () => {
  return (dispatch) => {
    signOut(auth).then(() => {
      dispatch(logout());
      // dispatch(cleanSession());
    });
  };
};

export const isLogged = () => ({
  type: types.isLogged,
});

export const logout = () => ({
  type: types.logout,
});

export const resetPasswordAuth = (email) => {
  return () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        Swal.fire({
          title: 'Revisa tu bandeja',
          text: 'Te hemos enviado el correo para restablecer tu contraseña',
          icon: 'success',
          confirmButtonColor: '#3085d6',
        });
      })
      .catch(async (error) => {
        console.log(error);
      });
  };
};
