import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LogoClient1 from "../../assets/home/clients/logo-client-1.svg";
import LogoClient2 from "../../assets/home/clients/logo-client-2.svg";
import LogoClient3 from "../../assets/home/clients/logo-client-3.svg";
import LogoClient4 from "../../assets/home/clients/logo-client-4.svg";
import LogoClient5 from "../../assets/home/clients/logo-client-5.svg";
import LogoClient6 from "../../assets/home/clients/logo-client-6.svg";
import LogoClient7 from "../../assets/home/clients/logo-client-7.svg";
import LogoClient8 from "../../assets/home/clients/logo-client-8.svg";
import LogoClient9 from "../../assets/home/clients/logo-client-9.svg";
import AboutUsImg from "../../assets/home/about-us.png";
import { Col, Container, Row } from "react-bootstrap";

const firstRowClients = [
    {
        id: 1,
        img: LogoClient1,
        width: 219,
    },
    {
        id: 2,
        img: LogoClient2,
        width: 174,
    },
    {
        id: 3,
        img: LogoClient3,
        width: 94,
    },
    {
        id: 4,
        img: LogoClient4,
        width: 123,
    },
    {
        id: 5,
        img: LogoClient5,
        width: 117,
    },
    {
        id: 6,
        img: LogoClient6,
        width: 124,
    },
    {
        id: 7,
        img: LogoClient7,
        width: 238,
    },
    {
        id: 8,
        img: LogoClient8,
        width: 113,
    },
    {
        id: 9,
        img: LogoClient9,
        width: 169,
    }
];

const StyledDiv = styled.div`
    background: linear-gradient(
        to right,
        #9BCB49 0%,
        #9BCB49 10%,
        #FFFFFF 10%,
        #FFFFFF 100%
    );
    position: relative;
    padding-top: 400px;
    padding-bottom: 170px;
    overflow: hidden;
`;

const ClientsDiv = styled.div`
    width: 100%;
    position: absolute;
    top: 65px;
    left: 0;
    box-shadow: 0px 32.792px 65.583px 0px rgba(171, 190, 209, 0.40);
    .container {
        display: flex;
        // background: linear-gradient(
        //     to right,
        //     #FFFFFF 0%,
        //     #FFFFFF 30%,
        //     #9BCB49 30%,
        //     #9BCB49 100%
        // );
    }
    // background: linear-gradient(
    //     to right,
    //     #FFFFFF 0%,
    //     #FFFFFF 30%,
    //     #9BCB49 30%,
    //     #9BCB49 100%
    // );
    #title-div {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
            color: #000;
            font-family: Montserrat;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: 70%;
            margin-bottom: 0;
            span {
                font-size: 39px;
            }
        }
    }
    #logos-div {
        padding: 40px 40px 20px;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden; /* Asegurar que los logos no se desborden */
        .slick-slider {
            display: flex;
            align-items: center; /* Alinear verticalmente los logos */
            justify-content: space-between; /* Espaciado uniforme entre los logos */
            width: 100%;
        }
        .slick-slide {
            height: auto; /* Altura automática para ajustar al contenido */
            display: flex;
            align-items: center; /* Alinear verticalmente los logos */
            justify-content: center;
            margin-right: 50px; /* Espacio adicional entre los logos */
        }
        .slick-list {
            width: 100%;
            overflow: hidden; /* Asegurar que los logos no se desborden */
        }
        .slick-track {
            display: flex;
            align-items: center; /* Alinear verticalmente los logos */
            justify-content: space-between; /* Espaciado uniforme entre los logos */
            width: 100%;
        }
        .slick-slide img {
            max-width: 120%; /* Tamaño máximo para los logos */
            height: auto; /* Altura automática para ajustar al contenido */
        }
    }
`;

const ContactDiv = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-top: 50px;

    img {
        max-width: 100%;
        margin-right: 16px;
        border-radius: 4px;
        z-index: 2;
    }

    #form-div {
        flex: 1;
        border-radius: 18px;
        background: var(--Neutral-White, #FFF);
        box-shadow: 0px 7.018px 14.036px 0px rgba(171, 190, 209, 0.40);
        padding: 21px 27px;
        margin-left: 20px;
        h3 {
            color: #24393E;
            font-family: Montserrat;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        p {
            color: #24393E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 143.842%; /* 23.015px */
        }
        input, textarea {
            width: 100%;
            border-radius: 28px;
            border: 1.5px solid #CCC;
            padding: 15px 10px 15px 25px;
            color: #999;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 143.842%; /* 23.015px */
            margin: 10px 0;
        }
        input {
            height: 56px;
        }
        button {
            border-radius: 25.283px;
            background: #9BCB49;
            color: #24393E;
            text-align: center;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 20.227px; /* 101.133% */
            border: none;
            padding: 16px;
            width: 221px;
        }
    }
`;

export const Clients = () => {
    
    const [isMobile, setIsMobile] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    useEffect(() => {
        // Function that checks the width of the screen
        const checkScreenSize = () => {
            if (window.innerWidth < 768 || window.innerWidth > 1000) {
                // Mobile Screens
                setIsMobile(true);
            } else {
                // Desktop Screens
                setIsMobile(false); 
            }
        };

        // Call the function on load and on resize
        checkScreenSize();

        // Add event listener for when the window size changes
        window.addEventListener("resize", checkScreenSize);

        // Remove the event listener when the component is unmounted
        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <StyledDiv id="contact-us">
            <ClientsDiv className="container-clientes">
                <Container className="row">
                    <div id="title-div">
                        <h1 className="titulo-clientes">
                            {isMobile ? (
                                <>Nuestros<br /><span>clientes</span></>
                            ) : (
                                <>Nuestros <span>clientes</span></>
                            )}
                        </h1>
                    </div>

                    <div id="logos-div">
                        <Slider {...settings}>
                            {firstRowClients.map((client, index)=>
                                <div key={index}>
                                    <img src={client.img} alt="Logo" />
                                </div>
                            )}
                        </Slider>
                    </div>
                </Container>
            </ClientsDiv>
            
            <ContactDiv>
                <Row>
                    <Col xs={12} md={3} lg={4}>
                        <img src={AboutUsImg} alt="" />
                    </Col>
                    <Col xs={12} md={9} lg={8}>
                        <div id="form-div">
                            <h3>Enviar mensaje</h3>
                            <p>¡Estamos encantados de saber de ti! Por favor, utiliza este formulario para ponerte en contacto con nosotros. Ya sea que tengas preguntas o comentarios.</p>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} md={6} lg={6} >
                                        <input
                                            type="text"
                                            placeholder="Nombre completo"
                                            name="fullName"
                                            value={formData.fullName}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} lg={6} >
                                        <input 
                                            type="text"
                                            placeholder="Correo electrónico"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} lg={6} >
                                        <input
                                            type="text"
                                            placeholder="Teléfono"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} lg={6} >
                                        <input
                                            type="text"
                                            placeholder="Asunto"
                                            name="subject"
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <textarea
                                            rows="4"
                                            placeholder="Mensaje"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <button type="submit">Enviar</button>
                            </form>
                        </div>
                    </Col>
                </Row>
            </ContactDiv>
        </StyledDiv>
    )
}
