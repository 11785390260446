import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Trash, PlusCircleDotted } from 'react-bootstrap-icons';
import { addDoc, collection, deleteDoc, doc, getDocs, serverTimestamp } from "firebase/firestore";
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db, getDownloadURL, ref, storage, uploadBytes } from "../../firebase/firebase-config";
import { Button, Modal } from "react-bootstrap";
import { deleteObject } from "firebase/storage";
import FullScreenSpinner from "../spinner/Spiner";
import Swal from "sweetalert2";

const StyledDiv = styled.div`
    flex: 1;
    border-radius: 18px;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 7.018px 14.036px 0px rgba(171, 190, 209, 0.40);
    padding: 21px 27px;
    margin: 10px 20px;
    h3 {
        color: #24393E;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    p {
        color: #24393E;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 143.842%; /* 23.015px */
    }
    #add {
        padding: 0 25px;
        height: 40px;
        border: none;
        border-radius: 25px;
        margin: 10px;
        background: #9BCB49;
    }
`;

const ImageRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
        display: flex;
        align-items: center;
        img {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            margin-right: 12px;
        }
        h2 {
            font-size: 20px;
        }
    }
    button {
        width: 50px;
        height: 50px;
        border: none;
        border-radius: 50%;
        margin: 10px;
    }
    #edit {
        background: #9BCB49;
    }
    #delete {
        background: #FF6A74;
    }
    @media only screen and (width < 556px) {
        > div h2 {
            max-width: 150px;
            max-height: 40px;
            overflow-y: scroll;
            font-size: 12px;
        }
    }
`;

export const ManageImages = () => {
    const [images, setImages] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageToUpload, setImageToUpload] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleAddImage = async () => {
        setShowAddModal(false);
        setLoading(true);
        try {
            const imgName = `${imageToUpload.name}${Date.now()}`;
            const storageRef = ref(storage, imgName);
            const createdAt = serverTimestamp();
            await uploadBytes(storageRef, imageToUpload);
        
            const downloadURL = await getDownloadURL(storageRef);
            const newImageDocRef = await addDoc(collection(db, 'images'), { url: downloadURL, name: imgName, createdAt });
        
            setImages((prevImages) => [
                ...prevImages,
                { id: newImageDocRef.id, url: downloadURL, name: imgName, createdAt },
            ]);
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: '¡Muy bien!',
                text: 'Imagen agregada correctamente',
                showCancelButton: false,
                confirmButtonColor: '#9BCB49',
                confirmButtonText: 'Ok',
            });
        } catch (error) {
            console.error('Error adding image:', error);
            setLoading(false);
            Swal.fire({
                title: 'Algo salió mal...',
                text: 'Ocurrió un problema al agregar la imagen.',
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            });
        }
    };

    const handleDeleteImage = async () => {
        setShowDeleteConfirmation(false);
        setLoading(true);
        try {
            const imageRef = ref(storage, selectedImage.url);
            await deleteObject(imageRef);
        
            await deleteDoc(doc(db, 'images', selectedImage.id));
        
            setImages((prevImages) => prevImages.filter((img) => img.id !== selectedImage.id));
        
            setLoading(false);
            Swal.fire({
                icon: 'success',
                title: '¡Muy bien!',
                text: 'Imagen eliminada correctamente',
                showCancelButton: false,
                confirmButtonColor: '#9BCB49',
                confirmButtonText: 'Ok',
            });
        } catch (error) {
            console.error('Error deleting image:', error);
            setLoading(false);
            Swal.fire({
                title: 'Algo salió mal...',
                text: 'Ocurrió un problema al eliminar la imagen.',
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            });
        }
    };

    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'images'));
                const fetchedImages = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setImages(fetchedImages);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching images:', error);
                setLoading(false);
            }
        };
    
        fetchImages();
    }, []);

    return (
        <StyledDiv>
            <h3>Editar imágenes</h3>
            <p>Administra las imágenes que se mostrarán en el slider.</p>
            {images?.length === 4 ? 
                <p>Número máximo de imágenes alcanzado (4 max.), elimina alguna imagen para poder agregar una nueva.</p>
            : null }
            <button id="add" onClick={() => setShowAddModal(true)} disabled={!(images?.length < 4)}><PlusCircleDotted/> Agregar imagen</button>
            {images?.map((image, index) => 
                <ImageRow key={index}>
                    <div>
                        <img src={image.url} alt={index} />
                        <h2>{image.name}</h2>
                    </div>
                    <div id="button-div">
                        <button id="delete" onClick={() => {
                            setSelectedImage(image);
                            setShowDeleteConfirmation(true)}
                        }><Trash/></button>
                    </div>
                </ImageRow>
            )}

            {/* Modal for adding images */}
            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Input for selecting file */}
                    <input type="file" onChange={(e) => setImageToUpload(e.target.files[0])} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleAddImage()}>
                        Subir
                    </Button>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Confirmation modal for deleting images */}
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro de eliminar esta imagen?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleDeleteImage}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            {loading ? 
                <FullScreenSpinner/>
            : null}
        </StyledDiv>
    )
}