import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Home } from "../components/home/Home";
import { Login } from "../components/login/Login";
import styled from "styled-components";
import { ManageResources } from "../components/manageResources/ManageResources";
import { useDispatch, useSelector } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { login } from "../actions/auth";
import { Signup } from "../components/signup/Signup";

export const AppRouter = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { uid } = useSelector((state) => state?.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user?.uid) {
                dispatch(login(user.uid, user.displayName));
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });
    }, [dispatch]);

    const StyledDiv = styled.div`
        padding-top: 130px;
        @media only screen and (width <= 1200px) {
            padding-top: 145px;
        }
        @media only screen and (width <= 768px) {
            padding-top: 96px;
        }
    `;

    const redirectIniciarSesion = () => {
        if (uid) {
            navigate('/admin');
        } else {
            return <Login />;
        }
    };

    const redirectAdmin = () => {
        if (!uid) {
            navigate('/iniciar-sesion');
        } else {
            return <ManageResources />;
        }
    };

    const redirectSignUp = () => {
        if (!uid) {
            navigate('/iniciar-sesion');
        } else {
            return <Signup />;
        }
    };

    return (
        <StyledDiv>
            <Routes>
    
                {/* ----- PUBLIC ROUTES ----- */}
                <Route path='/' Component={Home}/>

                {/* AUTH */}
                <Route path='/iniciar-sesion' Component={redirectIniciarSesion} />

                {/* PRIVATE ROUTES */}
                <Route path='/admin' Component={redirectAdmin} />

                {/* PRIVATE ROUTES */}
                <Route path='/registrar-usuario' Component={redirectSignUp} />

                {/* REDIRECT */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </StyledDiv>
    );
};