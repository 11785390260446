import React from "react";
import styled, { keyframes } from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import Service1Bg from '../../assets/home/services/bg-services-1.png';
import Service2Bg from '../../assets/home/services/bg-services-2.png';
import Service3Bg from '../../assets/home/services/bg-services-3.png';
import Service4Bg from '../../assets/home/services/bg-services-4.png';
import Service5Bg from '../../assets/home/services/bg-services-5.png';
import Service6Bg from '../../assets/home/services/bg-services-6.png';
import Service1Icon from '../../assets/home/services/icon-services-1.svg';
import Service2Icon from '../../assets/home/services/icon-services-2.svg';
import Service3Icon from '../../assets/home/services/icon-services-3.svg';
import Service4Icon from '../../assets/home/services/icon-services-4.svg';
import Service5Icon from '../../assets/home/services/icon-services-5.svg';
import Service6Icon from '../../assets/home/services/icon-services-6.svg';
import { scrollToElement } from "../../utils/utils";

const CurrentServices = [
    {
        id: 0,
        name: "Partner HP",
        icon: Service1Icon,
        background: Service1Bg,
        desc: 'Ser socio HP nos brinda grandes ventajas garantizando a nuestros clientes herramientas y recursos personalizados de fácil acceso que, a su vez, permite la formación, generación de demanda y experiencia de gestión empresarial con mayor rapidez y de forma colaborativa.'
    },
    {
        id: 1,
        name: "Control de acceso",
        icon: Service2Icon,
        background: Service2Bg,
        desc: 'El control de acceso es un elemento básico de seguridad que formaliza quién tiene permiso para acceder a determinados lugares, datos, recursos y en qué condiciones.'
    },
    {
        id: 2,
        name: "Soluciones integrales en redes",
        icon: Service3Icon,
        background: Service3Bg,
        desc: 'El sistema de cableado estructurado de red es una solución integral de comunicaciones muy amplia y completa que incluye: el cableado, canalización, conexiones, etiquetado y la instalación de dispositivos requeridos para su correcto funcionamiento.'
    },
    {
        id: 3,
        name: "Cámaras de seguridad",
        icon: Service4Icon,
        background: Service4Bg,
        desc: 'Las cámaras de seguridad sirven para monitorear y tener un registro de las actividades de las personas en una vivienda, negocio o nave industrial.'
    },
    {
        id: 4,
        name: "Venta de equipos y accesorios",
        icon: Service5Icon,
        background: Service5Bg,
        desc: 'Distribución y comercialización de una amplia variedad de equipo de computo y accesorios, más de 20,000 productos nuevos de la más alta calidad a precios bajos y competitivos. Nuestro equipo de ventas está formado por Ejecutivos calificados para brindar una solución personalizada de compra al conocer sus necesidades y expectativas.'
    },
    {
        id: 5,
        name: "Soluciones de administración de impresión",
        icon: Service6Icon,
        background: Service6Bg,
        desc: 'Venta y renta de impresoras y multifuncionales, proporcionamos un excelente beneficio y ahorro a todas las compañías, empresas y hogares.'
    },
]

const StyledDiv = styled.div`
    padding-top: 50px;
    #sub {
        color: #9BCB49;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    h1 {
        color: #000;
        font-family: Montserrat;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%;
        margin-bottom: 40px;
        span {
            font-size: 47px;
        }
    }
    #desc {
        color: #000;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 55px;
    }
    button {
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 24.218px;
        font-style: normal;
        font-weight: 600;
        line-height: 23.621px; /* 97.531% */
        display: flex;
        width: 301px;
        padding: 16.534px 37.793px;
        justify-content: center;
        align-items: center;
        gap: 11.81px;
        border: none;
        border-radius: 85.629px;
        background: #9BCB49;
        margin-bottom: 114px;
    }
    .container {
        padding: 0 20px;
    }
`;

const fadeIn = keyframes`
    from {
        background: rgba(255, 255, 255, 0);
    }
    to {
        background: rgba(255, 255, 255, 1);
    }
`;

const StyledCard = styled.div`
    width: 100%;
    max-width: 389px;
    height: 320px;
    background: url(${({bg}) => bg}) center;
    transition: background 0.3s ease;
    background-position: cover;
    padding: 35px 25px 35px 35px;
    border-radius: 15px;
    margin-bottom: 27px;
    &:hover {
        animation: ${fadeIn} 0.3s ease;
        background: #FFF;
        box-shadow: 0px 14px 15px 0px rgba(171, 190, 209, 0.40);
        h4 {
            color: #000000;
        }
        p {
            visibility: visible;
            opacity: 1;
        }
    }

    img {
        margin-bottom: 16px;
    }

    h4 {
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #FFF; /* Initial color */
        transition: color 0.3s ease;
    }

    p {
        margin-top: 10px;
        color: #333;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.3s ease;
        max-height: 120px;
        overflow: scroll;
        padding-right: 10px;
    }
    @media only screen and (width < 992px) {
        margin: 10px auto;
    }
`;

export const Services = () => {
    return (
        <StyledDiv id="services">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <p id="sub">Que ofrecemos</p>
                        <h1>Servicios <br/><span>integrales</span></h1>
                        <p id="desc">Las soluciones TI son una gran herramienta de apoyo para la gestión y control de los distintos procesos empresariales.</p>
                        <button onClick={() => scrollToElement('contact-us')}>Saber más</button>
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                        <Row>
                            {CurrentServices?.map((cService)=>
                                <Col xs={12} md={12} lg={6} key={cService?.id}>
                                    {/* <CardContainer> */}
                                        <StyledCard bg={cService?.background}>
                                            <img src={cService?.icon} alt={cService?.name} />
                                            <h4>{cService?.name}</h4>
                                            <p>{cService?.desc}</p>
                                        </StyledCard>
                                    {/* </CardContainer> */}
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledDiv>
    )
}