import React from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import { ManageImages } from "./manageImages";
import { ManageVideo } from "./manageVideo";

const StyledDiv = styled.div`
    min-height: 70vh;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    h1 {
        margin-bottom: 50px;
        color: #24393E;
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    li button.active {
        color: #9BCB49 !important;
        font-size: 24px;
        font-weight: 700;
    }
    li .nav-link {
        color: #000 !important;
        font-size: 20px;
    }
    @media only screen and (width < 556px) {
        h1 {
            margin-bottom: 30px;
            font-size: 28px;
            padding: 0 14px;
        }
    }
`

export const ManageResources = () => {
    return (
        <StyledDiv>
            <Container>
                <h1>Administra el contenido de la página</h1>
                <Tabs
                    defaultActiveKey="images"
                    id="justify-tab"
                    className="mb-3"
                    justify
                >
                    <Tab eventKey="images" title="Imágenes">
                        <ManageImages/>
                    </Tab>
                    <Tab eventKey="video" title="Video">
                        <ManageVideo/>
                    </Tab>
                </Tabs>
            </Container>
        </StyledDiv>
    );
}