import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Navbar } from './components/navbar/Navbar';
import { Footer } from './components/footer/Footer';
import { AppRouter } from './routes/AppRouter';


const App = () => {
  return (
    <Router>
        <Navbar />
          <AppRouter/>
        <Footer/>
    </Router>
  );
};

export default App;