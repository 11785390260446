import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const FullscreenSpinnerWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(200, 200, 200, 0.4);
    z-index: 1000;
`;

const FullScreenSpinner = () => {
    return (
        <FullscreenSpinnerWrapper>
            <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Cargando...</span>
            </Spinner>
        </FullscreenSpinnerWrapper>
    );
};

export default FullScreenSpinner;
