import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import FacebookIcon from '../../assets/icons/icon-facebook.svg';
import InstagramIcon from '../../assets/icons/icon-instagram.svg';
import LinkedInIcon from '../../assets/icons/icon-linkedin.svg';
import CopyrightIcon from '../../assets/icons/Icon-copyright.svg';
import LogoPCU from '../../assets/logos/Logo PC Uniservicios White.png';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { scrollToElement } from "../../utils/utils";

const StyledFooter = styled.div`
    width: 100%;
    background: #000;
    min-height: 462px;
    position: relative;
    padding: 82px 0 132px;
    overflow: hidden;
    .container {
        display: flex;
        justify-content: space-between;
    }
    #logo {
        width: 290.786px;
        height: 50.334px;
        margin-bottom: 20px;
    }
    #contact {
        width: 292px;
    }
    #menu {
        width: 97px;
    }
    #social {
        width: 176px;
        div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
    }
    h2 {
        color: #FFF;
        font-family: Poppins;
        font-size: 21px;
        font-style: normal;
        font-weight: 500;
        line-height: 29.4px; /* 140% */
        margin-bottom: 25px;
    }
    p {
        color: #FFF;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 143.842%; /* 25.892px */
    }
    #copy-div {
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    #copyright {
        color: #9BCB49;
        font-family: Poppins;
        font-size: 16.8px;
        font-style: normal;
        font-weight: 400;
        line-height: 25.2px; /* 150% */
        text-align: center;
    }
    #menu p:hover, #social img:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    @media only screen and (width < 992px) {
        padding: 40px 0 100px;
        .container {
            flex-direction: column;
            align-items: center;
        }
        * {
            text-align: center;
        }
        h2 {
            font-size: 18px;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
        }
        #logo {
            width: 250px;
            height: auto;
        }
    }
`;

export const Footer = () => {
    const navigate = useNavigate();
    const { uid } = useSelector((state) => state?.auth);

    return (
        <StyledFooter>
            <Container>
                <img id="logo" src={LogoPCU} alt="PC Uniservicios" />
                {/* Contact */}
                <div id="contact">
                    <h2>Contacto</h2>
                    <p>Boulevard Forjadores de Puebla No. 7106 Int. 8. Col. Ignacio Romero Vargas C.P. 72120</p>
                    <p>proyectos@pcuniservicios.com.mx<br/>ventas@pcuniservicios.com.mx</p>
                    <p>(01 222) 2 26 50 24</p>
                </div>
                {/* Menu */}
                <div id="menu">
                    <h2>Menú</h2>
                    <p onClick={() => window.location.href = '/'}>Inicio</p>
                    <p onClick={() => scrollToElement('about-us')}>Conócenos</p>
                    <p onClick={() => scrollToElement('services')}>Servicios</p>
                    <p onClick={() => window.open("https://pcuniservicios.blogspot.com/", "_blank")}>Blog</p>
                    <p onClick={() => navigate(uid ? '/admin' : '/iniciar-sesion')}>Administración</p>
                    <p onClick={() => navigate(uid ? '/registrar-usuario' : '/iniciar-sesion')}>Registro</p>
                </div>
                {/* Social */}
                <div id="social">
                    <h2>Redes sociales</h2>
                    <div>
                        <img src={FacebookIcon} alt="Facebook" onClick={() => window.open("https://www.facebook.com/profile.php?id=100063534133883&ref=hl", "_blank")}/>
                        <img src={InstagramIcon} alt="Instagram" onClick={() => window.open("https://www.instagram.com/pcuniservicios/", "_blank")}/>
                        <img src={LinkedInIcon} alt="LinkedIn" onClick={() => window.open("https://www.linkedin.com/in/pc-uniservicios/", "_blank")}/>
                    </div>
                </div>
            </Container>
            <div id="copy-div">
                <hr />
                <p id="copyright"><img src={CopyrightIcon} alt="Copyright"/> Copyright  2024. All right reserved</p>
            </div>
        </StyledFooter>
    )
}
