import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LogoPCU from '../../assets/logos/Logo PC Uniservicios 1.png';
import FacebookIcon from '../../assets/icons/icon-facebook.svg';
import InstagramIcon from '../../assets/icons/icon-instagram.svg';
import LinkedInIcon from '../../assets/icons/icon-linkedin.svg';
import BurguerIcon from '../../assets/icons/burguer.png';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { startLogout } from '../../actions/auth';
import { scrollToElement } from '../../utils/utils';

const sections = [
    { id: 'about-us', label: 'Conócenos' },
    { id: 'services', label: 'Servicios' },
    { id: 'contact-us', label: 'Contáctanos' },
];

const WhiteBar = styled.div`
    width: 100%;
    height: 70px;
    flex-shrink: 0;
    background: #FFF;
    .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }
    @media only screen and (width <= 1200px) and (width > 768px) {
        height: 75px;
    }
    @media only screen and (width <= 768px) {
        height: 50px;
    }
`;

const BlackBar = styled.nav`
    background-color: #000;
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    .container {
        padding: 0 20px;
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }
    @media only screen and (width <= 1200px) and (width > 768px) {
        height: 70px;
    }
    @media only screen and (width <= 768px) {
        height: 46px;
    }
`;

const Logo = styled.img`
    width: 270px;
    flex-shrink: 0;
    @media only screen and (width <= 1200px) and (width > 768px) {
        width: 200px;
    }
    @media only screen and (width <= 768px) {
        width: 170px;
    }
`;

const NavElements = styled.ul`
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    align-items: center;
    height: 100%;
    padding: 0;
    margin-bottom: 0;
    width: 100%;
    max-width: 900px;
    margin-right: 60px;
    @media only screen and (width <= 768px) {
        li {
            display: none;
        }
        .catalogs {
            display: none;
        }
    }
`;

const NavLinkElement = styled.a`
    color: #FFFFFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    transition: all ease .2s;
    &:hover {
        color: #9BCB49;
        text-decoration: underline;
    }
    &.active {
        color: #9BCB49;
        text-decoration: underline;
    }
    @media only screen and (width <= 1200px) {
        font-size: 16px;
    }
    @media only screen and (width <= 768px) {
        margin-bottom: 8px;
        text-align: left;
        padding-left: 8px;
    }
`;

const IconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        margin-bottom: 0;
    }
`;

const BurguerDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Ajustado para alinear a la izquierda */
    @media only screen and (width > 768px) {
        img {
            display: none;
        }
    }
    img {
        width: 20px;
        height: 15px;
    }
    #dropdown {
        transition: all 0.3s ease;
        width: ${({ openedmenu }) => (openedmenu === 'true' ? '200px' : '0px')};
        height: 230px;
        padding: 12px;
        display: flex;
        position: absolute;
        bottom: -200px;
        left: -20px;
        z-index: 20;
        background: #000;
        flex-direction: column;
        opacity: ${({ openedmenu }) => (openedmenu === 'true' ? 1 : 0)};
    }
`;

const LogButton = styled.div`
    background: #9BCB49;
    padding: 10px 30px;
    border-radius: 20px;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
    @media only screen and (width < 556px) {
        padding: 5px 24px;
        font-size: 16px;
    }
`;

const Dropdown = styled.div`
    position: relative;
    display: inline-block;
    &:hover .dropdown-content {
        display: block;
    }
`;

const DropdownContent = styled.div`
    display: none;
    position: absolute;
    background-color: #000;
    min-width: 160px;
    z-index: 1;
    a {
        color: white;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        &:hover {
            background-color: #575757;
        }
    }
`;

export const Navbar = () => {
    const [openedmenu, setOpenedmenu] = useState(false);
    const { uid } = useSelector((state) => state?.auth);
    const dispatch = useDispatch();

    document.addEventListener('click', function (event) {
        if (event.target.id !== 'menu-btn' && event.target.id !== 'dropdown') {
            setOpenedmenu(false);
        }
    });
    const openMenu = () => {
        setOpenedmenu(true);
    };

    // To handle scroll and active id
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const offset = 0;

            for (const section of sections) {
                const sectionElement = document.getElementById(section.id);

                if (
                    sectionElement &&
                    window.scrollY >= sectionElement.offsetTop - offset &&
                    window.scrollY <= sectionElement.offsetTop + sectionElement.offsetHeight - offset
                ) {
                    setActiveLink(section.id);
                    return;
                }
            }

            setActiveLink('');
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 50, width: '100%' }}>
            {/* Logo bar */}
            <WhiteBar id="white-bar">
                <Container>
                    <Logo src={LogoPCU} alt="PC Uniservicios" />
                    {uid ? (
                        <LogButton onClick={() => dispatch(startLogout())}>
                            Cerrar sesión
                        </LogButton>
                    ) : null}
                </Container>
            </WhiteBar>

            {/* Navigation bar */}
            <BlackBar>
                <Container>
                    <BurguerDiv openedmenu={openedmenu.toString()}>
                        <img src={BurguerIcon} alt="Menu" onClick={openMenu} id="menu-btn" />
                        {openedmenu ? (
                            <div id="dropdown">
                                <NavLinkElement onClick={() => window.location.href = '/'}>
                                    Inicio
                                </NavLinkElement>
                                <NavLinkElement onClick={() => scrollToElement('about-us')} className={activeLink === 'about-us' ? 'active' : ''}>
                                    Conócenos
                                </NavLinkElement>
                                <NavLinkElement onClick={() => scrollToElement('services')} className={activeLink === 'services' ? 'active' : ''}>
                                    Servicios
                                </NavLinkElement>
                                <NavLinkElement href="https://pcuniservicios.blogspot.com/" target="_blank">
                                    Blog
                                </NavLinkElement>
                                <NavLinkElement onClick={() => scrollToElement('contact-us')} className={activeLink === 'contact-us' ? 'active' : ''}>
                                    Contacto
                                </NavLinkElement>
                                <NavLinkElement href="https://online.fliphtml5.com/cwpng/zwfy//bdpc/#p=1" target="_blank">
                                    Productos
                                </NavLinkElement>
                                <NavLinkElement href="https://online.fliphtml5.com/cwpng/rsyy/#p=1" target="_blank">
                                    Papelería
                                </NavLinkElement>
                                <NavLinkElement href="https://online.fliphtml5.com/cwpng/fxcn/#p=1" target="_blank">
                                    Servicios
                                </NavLinkElement>
                            </div>
                        ) : null}
                    </BurguerDiv>
                    <NavElements>
                        <li>
                            <NavLinkElement onClick={() => window.location.href = '/'}>
                                Inicio
                            </NavLinkElement>
                        </li>
                        <li>
                            <NavLinkElement onClick={() => scrollToElement('about-us')} className={activeLink === 'about-us' ? 'active' : ''}>
                                Conócenos
                            </NavLinkElement>
                        </li>
                        <li>
                            <NavLinkElement onClick={() => scrollToElement('services')} className={activeLink === 'services' ? 'active' : ''}>
                                Servicios
                            </NavLinkElement>
                        </li>
                        <li>
                            <NavLinkElement href="https://pcuniservicios.blogspot.com/" target="_blank">
                                Blog
                            </NavLinkElement>
                        </li>
                        <li>
                            <NavLinkElement onClick={() => scrollToElement('contact-us')} className={activeLink === 'contact-us' ? 'active' : ''}>
                                Contacto
                            </NavLinkElement>
                        </li>
                        {!openedmenu && ( // Ocultar la opción Catálogos si el menú hamburguesa está abierto
                        <li className="catalogs">
                            <Dropdown>
                                <NavLinkElement>Catálogos</NavLinkElement>
                                <DropdownContent className="dropdown-content">
                                    <NavLinkElement href="https://online.fliphtml5.com/cwpng/bdpc/#p=1" target="_blank">
                                        Productos
                                    </NavLinkElement>
                                    <NavLinkElement href="https://online.fliphtml5.com/cwpng/rsyy/#p=1" target="_blank">
                                        Papelería
                                    </NavLinkElement>
                                    <NavLinkElement href="https://online.fliphtml5.com/cwpng/fxcn/#p=1" target="_blank">
                                        Servicios
                                    </NavLinkElement>
                                </DropdownContent>
                            </Dropdown>
                        </li>
                        )}
                    </NavElements>
                    
                    <IconsContainer>
                        <NavLinkElement className="icon-nav-link" onClick={() => window.open('https://www.facebook.com/profile.php?id=100063534133883&ref=hl', '_blank')}>
                            <img src={FacebookIcon} alt="Facebook" />
                        </NavLinkElement>
                        <NavLinkElement className="icon-nav-link-igm" onClick={() => window.open('https://www.instagram.com/pcuniservicios', '_blank')}>
                            <img src={InstagramIcon} alt="Instagram" />
                        </NavLinkElement>
                        <NavLinkElement className="icon-nav-link" onClick={() => window.open('https://www.linkedin.com/in/pc-uniservicios/', '_blank')}>
                            <img src={LinkedInIcon} alt="Linkedin" />
                        </NavLinkElement>
                    </IconsContainer>
                </Container>
            </BlackBar>
        </div>
    );
};
        