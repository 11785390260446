import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import photo1 from "../../assets/home/photosSlider/sliderPhoto1.png";
import photo2 from "../../assets/home/photosSlider/sliderPhoto2.png";
import photo3 from "../../assets/home/photosSlider/sliderPhoto3.png";
import photo4 from "../../assets/home/photosSlider/sliderPhoto4.png";
import last from "../../assets/icons/icon-last.svg";
import next from "../../assets/icons/icon-next.svg";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";

const photos = [photo1, photo2, photo3, photo4];

const StyledDiv = styled(Container)`
    background: linear-gradient(
        to right,
        #FFFFFF 0%,
        #FFFFFF 60%,
        #9BCB49 60%,
        #9BCB49 100%
    );
    padding: 35px 22px;
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MainImage = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    img {
        width: 100%;
    }
    button {
        width: 73px;
        height: 73px;
        flex-shrink: 0;
        background: #9BCB49;
        border: none;
        border-radius: 50%;
        color: #FFFFFF;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 20px;
        img {
            width: 20px;
        }
    }
    #last {
        left: 20px;
        padding-right: 10px;
    }
    #next {
        right: 20px;
        padding-left: 10px;
    }
    @media only screen and (width < 768px) {
        #last {
            left: 1px;
        }
        #next {
            right: 10px;
        }
        button {
            width: 45px;
            height: 45px;
            img {
                width: 12px;
            }
        }
    }
`;

const ImagesDiv = styled.div`
    width: 326px;
    height: 100%;
    margin-left: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    img {
        margin: 3px 0;
        width: 326px;
    }
    @media only screen and (width < 992px) { 
        display: none;
    }
`;

export const PhotosSlider = () => {
    const [selectedPhoto, setSelectedPhoto] = useState(0);
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'images'));
                const fetchedImages = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setImages(fetchedImages);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
    
        fetchImages();
    }, []);

    const handleChange = (type) => {
        if(type === 0){
            if(selectedPhoto === 0){
                setSelectedPhoto(images?.length - 1);
            }else{
                setSelectedPhoto(selectedPhoto-1);
            }
        }else{
            if(selectedPhoto === (images.length - 1)){
                setSelectedPhoto(0);
            }else{
                setSelectedPhoto(selectedPhoto+1);
            }
        }
    }

    return (
        <StyledDiv>
            {images?.length >= 1 ? 
                <>
                <MainImage>
                    {images?.length > 1 ? 
                        <button id="last" onClick={() => handleChange(0)}><img src={last} alt="anterior" /></button>
                    : null }
                        <img src={images[selectedPhoto]?.url} alt="" />
                    {images?.length > 1 ? 
                        <button id="next" onClick={() => handleChange(1)}><img src={next} alt="siguiente" /></button>
                    : null}
                </MainImage>
                <ImagesDiv>
                    {images?.map((photo, index) =>
                        {
                            if(index !== selectedPhoto){
                                return <img src={photo?.url} alt={`Img${index}`} key={index}/>;
                            }else{
                                return null;
                            }
                        }
                    )}
                </ImagesDiv>
                </>
            : null}
        </StyledDiv>
    )
}