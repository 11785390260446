import React from "react";
import styled from "styled-components";
import KnowImg from "../../assets/home/know-img.png";
import ValuesMision from "../../assets/home/valores-mision.svg";
import Vision from "../../assets/home/vision.svg";
import { Col, Container, Row } from "react-bootstrap";
import { scrollToElement } from "../../utils/utils";

const StyledDiv = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
    #green-bg {
        position: absolute;
        width: 100%;
        height: 603px;
        background: #9BCB49;
        z-index: 0;
    }
    .container {
        display: flex;
        justify-content: space-around;
        * {
            z-index: 1;
        }
    }
    #about-us, #values, #mision, #vision {
        background: #FFF;
        box-shadow: 0px 32.792px 65.583px 0px rgba(171, 190, 209, 0.40);
    }
    #values, #mision, #vision {
        width: 90%;
        max-width: 550px;
        background: #FFF;
        box-shadow: 0px 32.792px 65.583px 0px rgba(171, 190, 209, 0.40);
    }
    #mvv {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    #about-us {
        padding: 102px 78px 0;
        img {
            width: 110%;
            max-width: 614px;
            margin-left: -5%;
        } 
        p {
            color: #9BCB49;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 0;
        }
        h2 {
            color: #000;
            font-family: Montserrat;
            font-size: 64px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 53px;
        }
        button {
            color: #FFF;
            text-align: center;
            font-family: Montserrat;
            font-size: 24.218px;
            font-style: normal;
            font-weight: 600;
            line-height: 23.621px; /* 97.531% */
            display: flex;
            width: 301px;
            padding: 16.534px 37.793px;
            justify-content: center;
            align-items: center;
            gap: 11.81px;
            border: none;
            border-radius: 85.629px;
            background: #9BCB49;
            margin-bottom: 114px;
        }
    }
    #values, #mision, #vision {
        height: 284px;
        width: 100%;
        margin-top: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        border: 1px solid #ccc;
        border-radius: 8px;
        .content {
            flex: 1;
            h3 {
                margin-bottom: 27px;
            }
            p, li {
                color: #000;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 146.28%; /* 26.33px */
            }
        }
    }
    @media only screen and (width >= 768px) and (width < 1200px) {
        #about-us {
            position: relative;
            img {
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 40px;
                width: 350px;
            }
        }
    }
    
    @media only screen and (width >= 768px) and (width < 992px) {
        #about-us {
            img {
                width: 300px;
            }
            h2 {
                font-size: 50px;
            }
        }
    }

    @media only screen and (width < 768px) {
        #about-us {
            padding: 40px 20px 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;
            p {

            }
            h2 {
                font-size: 45px;
                margin-bottom: 20px;
            }
            button {
                margin-bottom: 30px;
            }
            img {
                width: 90%;
                margin: 0 5%;
            }
        }
        #values, #mision, #vision {
            height: auto;
            padding: 20px 16px;
            margin-top: 30px;
            .content {
                h3 {
                    margin-bottom: 16px;
                }
                p, li {
                    color: #000;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 146.28%;
                }
            }
            img {
                width: 80px;
                margin-right: 15px;
            }
        }
    }
`;

export const AboutUs = () => {
    return (
        <StyledDiv>
            <div id="green-bg"/>
            <Container>
                <Row>
                    <Col xs={12} xl={6} id="about-us">
                        <p>¿Quiénes somos?</p>
                        <h2>Conócenos</h2>
                        <button onClick={() => scrollToElement('contact-us')}>Contáctanos</button>
                        <img src={KnowImg} alt="Conócenos" />
                    </Col>
                    <Col xs={12} xl={6} id="mvv" >
                        <div id="values">
                            <img src={ValuesMision} alt="Valores" />
                            <div className="content">
                                <h3>Valores</h3>
                                <ul>
                                    <li>Comunicación efectiva </li>
                                    <li>Mejora continua </li>
                                    <li>Eficacia y eficiencia </li>
                                    <li>Responsabilidad social empresarial </li>
                                    <li>Trabajo en equipo</li>
                                </ul>
                            </div>
                        </div>
                        <div id="mision">
                            <img src={ValuesMision} alt="Misión" />
                            <div className="content">
                                <h3>Misión</h3>
                                <p>Ofrecer soluciones, productos y servicios tecnológicos de alta calidad, para el beneficio de nuestros clientes.</p>
                            </div>
                        </div>
                        <div id="vision">
                            <img src={Vision} alt="Visión" />
                            <div className="content visionContent">
                                <h3>Visión</h3>
                                <p>Posicionarse como una empresa en
                                    integración de tecnología, por la
                                    capacidad  y  confiabilidad  de  nuestros
                                    productos y servicios respaldados por
                                    una  red  solida  de  mayoristas  y  marcas
                                    líderes en el mercado</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledDiv>
    )
}